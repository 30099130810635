import React, { useEffect, useState, useRef, useReducer } from 'react'
import classnames from 'classnames/bind'
import PropTypes from 'prop-types'
import { useRouter } from 'next/router'
import qs from 'qs'
import _ from 'lodash'

import simplePluralize from '../../utils/simple-pluralize'
import PaginationButton from './PaginationButton'
import CardContainer from './CardContainer'
import css from './styles.module.scss'

const cx = classnames.bind(css)

function reducer(state, action) {
  switch (action.type) {
    case 'fetchingPrevPage':
      return { ...state, isFetchingPrevPage: true }
    case 'prevPageFetched':
      return {
        ...state,
        isFetchingPrevPage: false,
        cards: [...action.payload.cards, ...state.cards],
      }
    case 'fetchingNextPage':
      return { ...state, isFetchingNextPage: true }
    case 'nextPageFetched':
      return {
        ...state,
        isFetchingNextPage: false,
        cards: [...state.cards, ...action.payload.cards],
      }
    default:
      throw new Error()
  }
}

const fetchPackages = async ({ page, pageSize, query }) => {
  if (query?.collections && !Array.isArray(query?.collections)) {
    query.collections = Array(query.collections)
  }

  if (query?.categories && !Array.isArray(query?.categories)) {
    query.categories = Array(query.categories)
  }

  const paginationParams = {
    page,
    pageSize,
  }

  delete query.page
  const queryWithPagination = { ...query, pagination: paginationParams }
  const queryString = qs.stringify(queryWithPagination, { encode: false })

  const result = await fetch(
    `${process.env.NEXT_PUBLIC_MARKETPLACE_API_URL}/plugins${
      query ? `?${queryString}` : ''
    }`
  )

  const { data } = await result.json()
  return data
}

const formatCards = (cards, pluralPackageType) => {
  return cards.map((npmPackage) => ({
    npmPackage,
    link: {
      href: {
        pathname: `/marketplace/${pluralPackageType}/[slug]`,
        query: {
          slug: npmPackage.attributes.slug,
        },
      },
      as: `/${pluralPackageType}/${npmPackage.attributes.slug}`,
    },
  }))
}

const CardsGridWithPagination = ({
  className,
  cards,
  customCardComponent,
  customCardProps,
  pagination,
}) => {
  const { isList, npmPackageType } = customCardProps
  const { page, pageCount, total, pageSize } = pagination
  const pluralPackageType = simplePluralize(npmPackageType)
  const router = useRouter()
  const initialRender = useRef(true)
  const [hasPrev, setShowPrev] = useState(false)
  const initialPage = router.query.pagination?.page || page
  const [loadMoreDisabled, setDisabled] = useState(false)
  const [prevPageCounter, setPrevPageCounter] = useState(initialPage)
  const [nextPageCounter, setNextPageCounter] = useState(initialPage)

  const [state, dispatch] = useReducer(reducer, {
    cards,
    nbPackages: total,
    isFetchingPrevPage: false,
    isFetchingNextPage: false,
  })

  // previous button visibility on initial render
  useEffect(() => {
    if (initialRender.current) {
      if (prevPageCounter > 1) setShowPrev(true)
    }
  }, [prevPageCounter])

  // previous button visibility on prev click
  useEffect(() => {
    if (prevPageCounter <= 1) setShowPrev(false)
  }, [prevPageCounter])

  useEffect(() => {
    const fetchMore = async () => {
      dispatch({ type: 'fetchingNextPage' })
      const cards = await fetchPackages({
        page: nextPageCounter,
        pageSize,
        query: router.query,
      })

      dispatch({
        type: 'nextPageFetched',
        payload: {
          cards: formatCards(cards, pluralPackageType),
        },
      })
    }

    if (!initialRender.current) {
      fetchMore()
    }
  }, [nextPageCounter])

  useEffect(() => {
    const fetchPrev = async () => {
      dispatch({ type: 'fetchingPrevPage' })
      const cards = await fetchPackages({
        page: prevPageCounter,
        pageSize,
        query: router.query,
      })

      dispatch({
        type: 'prevPageFetched',
        payload: {
          cards: formatCards(cards, pluralPackageType),
        },
      })
    }

    if (!initialRender.current) {
      fetchPrev()
    }
  }, [prevPageCounter])

  // disable load more button if last page is loaded
  useEffect(() => {
    if (nextPageCounter === pageCount) setDisabled(true)
  }, [nextPageCounter, pageCount])

  useEffect(() => {
    initialRender.current = false
  }, [])

  if (state.cards.length < 1) {
    return null
  }

  return (
    <>
      {hasPrev && (
        <PaginationButton
          buttonType="prev"
          loading={state.isFetchingPrevPage}
          page={prevPageCounter}
          pathname={`/${pluralPackageType}`}
          className={css.loadPrev}
          onClick={(event) => {
            event.preventDefault()
            if (prevPageCounter > 1)
              setPrevPageCounter((prevState) => prevState - 1)
          }}
        >
          Load previous items
        </PaginationButton>
      )}
      <div
        className={cx(css.CardsGridWithPagination, className, {
          isList,
        })}
      >
        {state.cards && (
          <CardContainer
            cards={state.cards}
            customCardComponent={customCardComponent}
            customCardProps={customCardProps}
          />
        )}
      </div>

      <>
        <p className={css.packagesViewed}>
          You have seen {state.cards.length} {pluralPackageType} out of {total}
        </p>

        <PaginationButton
          buttonType="next"
          isLoading={state.isFetchingNextPage}
          page={nextPageCounter}
          pathname={`/${pluralPackageType}`}
          disabled={loadMoreDisabled}
          className={css.loadMore}
          onClick={(event) => {
            event.preventDefault()
            if (nextPageCounter < pageCount)
              setNextPageCounter((prevState) => prevState + 1)
          }}
        >
          Load More
        </PaginationButton>
      </>
    </>
  )
}

CardsGridWithPagination.defaultProps = {
  className: null,
  cards: [],
  customCardProps: null,
}

CardsGridWithPagination.propTypes = {
  className: PropTypes.string,
  cards: PropTypes.array,
  customCardComponent: PropTypes.elementType.isRequired,
  customCardProps: PropTypes.object,
  pagination: PropTypes.object.isRequired,
}

export default CardsGridWithPagination
