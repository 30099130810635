import Img from 'next/image'

import Button from 'components/shared/Button'

import css from './styles.module.scss'
import classnames from 'classnames/bind'
const cx = classnames.bind(css)

import submitPluginIcon from 'public/assets/marketplace_upload-submit-icon.svg'

const MarketplaceSubmitButton = ({ npmPackageType }) => {
  return (
    <Button
      className={cx(css.submitButton, css.desktop)}
      theme="marketplaceWhite"
      link={{
        href: `/marketplace/submit-${npmPackageType}`,
        as: `/submit-${npmPackageType}`,
      }}
    >
      <div className={css.buttonContent}>
        <Img
          src={submitPluginIcon}
          alt={`Submit ${npmPackageType} icon`}
          width={12}
          height={14}
        />
        <span className={css.buttonText}>Submit {npmPackageType}</span>
      </div>
    </Button>
  )
}

export default MarketplaceSubmitButton
