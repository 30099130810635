import React from 'react'
import classnames from 'classnames/bind'
import { useRouter } from 'next/router'
import qs from 'qs'
import PropTypes from 'prop-types'
import Img from 'next/image'

import LoadingIcon from 'public/assets/marketplace_loading_icon.svg'
import SmallArrow from 'components/icons/SmallArrow'
import Button from 'components/shared/Button'
import css from './styles.module.scss'

const cx = classnames.bind(css)

const PaginationButton = ({
  onClick,
  pathname,
  page,
  buttonType,
  isLoading,
  disabled,
  className,
  children,
}) => {
  const router = useRouter()
  const { query } = router

  const formatPaginationUrl = () => {
    const queryString = {
      ...query,
      page: buttonType === 'next' ? page + 1 : page - 1,
    }
    return `${pathname}${query ? `?${qs.stringify(queryString)}` : ''}`
  }

  const href = formatPaginationUrl()

  return (
    <div className={cx(css.buttonContainer, className)}>
      <Button
        className={cx(css.button, { loading: isLoading })}
        theme="white"
        onClick={(e) => {
          e.preventDefault()
          window.history.replaceState(null, '', href)
          onClick && onClick(e)
        }}
        href={href}
        disabled={disabled}
      >
        {children}
        {isLoading ? (
          <div className={css.imageWrapper}>
            <Img src={LoadingIcon} width={16} height={16} alt="loading icon" />
          </div>
        ) : (
          <SmallArrow
            theme="current-color"
            className={cx(
              css.smallArrow,
              buttonType === 'next' ? css.arrowDown : css.arrowUp
            )}
          />
        )}
      </Button>
    </div>
  )
}

PaginationButton.defaultProps = {
  isLoading: false,
  disabled: false,
  className: null,
}

PaginationButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired,
  page: PropTypes.number.isRequired,
  buttonType: PropTypes.oneOf(['next', 'prev']).isRequired,
  isLoading: PropTypes.boolean,
  disabled: PropTypes.boolean,
  className: PropTypes.node,
  children: PropTypes.element.isRequired,
}

export default PaginationButton
