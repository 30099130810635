import React from 'react'
import PropTypes from 'prop-types'

import CardWithArrowLink from 'components/shared/CardWithArrowLink'
import useRefs from 'src/hooks/useRefs'
import css from './styles.module.scss'

const CardContainer = ({
  cards,
  customCardComponent: Component,
  customCardProps,
}) => {
  const cardsRefs = useRefs(cards.length)

  return cards.map((card, index) => {
    const { link, ...rest } = card
    const cardProps = {
      ref: cardsRefs.current[index],
      image: card.coverImage || card.image,
      ...(customCardProps.fullCardLink && link && { link }),
    }

    return (
      <div key={`card-${card.title}-${index}`} className={css.cardContainer}>
        <CardWithArrowLink
          customCardProps={customCardProps}
          {...rest}
          {...cardProps}
        >
          <Component
            className={css.cardContainer}
            link={link}
            {...customCardProps}
            {...rest}
          />
        </CardWithArrowLink>
      </div>
    )
  })
}

CardContainer.defaultProps = {
  cards: [],
  customCardProps: null,
}

CardContainer.propTypes = {
  cards: PropTypes.array,
  customCardComponent: PropTypes.elementType.isRequired,
  customCardProps: PropTypes.object,
}

export default CardContainer
