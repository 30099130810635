import PropTypes from 'prop-types'

import CardsGrid from 'components/shared/CardsGrid'
import simplePluralize from '../utils/simple-pluralize'
import MarketplaceCard from '../MarketplaceCard'
import CardsGridWithPagination from './CardsGridWithPagination'
import css from './styles.module.scss'

const MarketplaceCardGrid = ({
  npmPackages,
  npmPackageType,
  withPagination = false,
  pagination = {},
}) => {
  const pluralPackageType = simplePluralize(npmPackageType)

  const cards = npmPackages.map((npmPackage) => ({
    npmPackage,
    link: {
      href: {
        pathname: `/marketplace/${pluralPackageType}/[slug]`,
        query: {
          slug: npmPackage.attributes.slug,
        },
      },
      as: `/${pluralPackageType}/${npmPackage.attributes.slug}`,
    },
  }))

  const Component = withPagination ? CardsGridWithPagination : CardsGrid

  return (
    <Component
      cards={cards}
      customCardComponent={MarketplaceCard}
      customCardProps={{
        fullCardLink: true,
        isList: true,
        npmPackageType,
      }}
      className={css.marketplaceCardGrid}
      pagination={withPagination && pagination}
    />
  )
}

MarketplaceCardGrid.propTypes = {
  npmPackages: PropTypes.array.isRequired,
  npmPackageType: PropTypes.string.isRequired,
  withPagination: PropTypes.bool,
  pagination: PropTypes.object,
}

export default MarketplaceCardGrid
